@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  font-family: "Plus Jakarta Sans", sans-serif!important;
}

.bg-desktop-gradient {
  background: linear-gradient(269.68deg, #57ABFF -28.39%, rgba(249, 250, 251, 0) 55.88%, #57ABFF 143.59%);
}

.bg-mobile-gradient {
  background: linear-gradient(274.85deg, #57ABFF -68.22%, rgba(249, 250, 251, 0) 48.74%, #57ABFF 170.48%);
}

.custom-skeleton-image {
  width: 100%!important;
  height: 100%!important;
}

.ant-form-item-label > label {
  font-size: 16px !important; /* Using !important to ensure override */
}

.ant-tabs .ant-tabs-tab-btn {
  color: #1D4ED8;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1E40AF;
  font-weight: 600;
}

.custom-collapse >.ant-collapse-item >.ant-collapse-header {
  padding: 8px !important;
}

.custom-table .ant-table-cell {
  font-size: 16px;
  color: #1E40AF !important;
}

.custom-desktop-checkbox label {
  width: 32%;
}

.custom-mobile-checkbox label {
  width: 100%;
}

/* Customizes the entire scrollbar */
::-webkit-scrollbar {
  width: 9px;
}

/* Customizes the track (part the thumb slides within) */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Customizes the thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background: #1E3A8A;
  border-radius: 8px;
}

/* Hover effect for the thumb */
::-webkit-scrollbar-thumb:hover {
  background: #1D4ED8;
  border-radius: 8px;
}

.custom-button {
  background-color: white;
  color: #1E3A8A;
}

.custom-button:hover {
  background-color: #1E3A8A!important;
  color: white!important;
  border-color: #1E3A8A!important;
}

.ant-form-item-label {
  font-weight: 600;
}

.capitalize-first-letter::first-letter {
  text-transform: capitalize;
}

@keyframes moveDown {
  0% {
    bottom: 0px;
  }
  50% {
    bottom: 32px;
  }
  100% {
    bottom: 0px;
  }
}

.scroll-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  right: 20px;
  bottom: 60px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  color: #1d4ed8;
  z-index: 1000;
}

.scroll-container > *:not(:last-child) {
  margin-bottom: 0.25rem;
}

.scroll-container * {
  color: #1d4ed8;
  transition: all 0.3s ease;
}

.icon-indicator {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  font-weight: bolder;
  animation: moveDown 2s infinite;
  border-color: #1d4ed8;
}

.scroll-container:hover div {
  border-color: #1d4ed8;
}

.full-height-content {
  height: calc(var(--vh, 1vh) * 100);
}

/* .ant-form-item .react-tel-input .form-control {
  font-family: "Plus Jakarta Sans", sans-serif!important;
  border-color: #93C5FD!important;
  background-color: #EFF6FF!important;
  color: #1E40AF!important;
  font-weight: 500!important;
}

.ant-form-item .react-tel-input .flag-dropdown {
  border-color: #93C5FD!important;
  background-color: transparent!important;
}

.ant-form-item .react-tel-input .flag-dropdown:hover {
  border-color: #93C5FD!important;
}

.ant-form-item .react-tel-input .selected-flag:hover, .ant-form-item .flag-dropdown.open .selected-flag {
  border-color: #93C5FD!important;
  background-color: transparent!important;
}

.ant-form-item-has-error .react-tel-input .form-control {
  border-color: #ff4d4f!important;
  background-color: #EFF6FF!important;
}

.ant-form-item-has-error .react-tel-input .flag-dropdown {
  border-color: #ff4d4f!important;
  background-color: transparent!important;
}

.ant-form-item-has-error .react-tel-input .flag-dropdown:hover {
  border-color: #ff4d4f!important;
} */